import React, { FC } from 'react';

import { RelatedProductsTitleProps } from 'gatsby-theme-husky/src/components/RelatedProducts/RelatedProductsTitle/model';

const RelatedProductsTitle: FC<RelatedProductsTitleProps> = ({ title }) => {
  return (
    <div className="related-product__title">
      <h2>{title}</h2>
    </div>
  );
};

export default RelatedProductsTitle;
